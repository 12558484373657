.fullscreen-bg {
  position: relative;
  min-height: 100vh;
  background: #111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.cta-box {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  color: white;
}

.cta-box h1 {
  font-size: 4rem;
  margin-bottom: 2rem;
}

.cta-box p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  border-radius: 4px;
  color: white;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.cta-button {
  padding: 1rem 2rem !important;
  font-size: 1rem !important;
  border-radius: 4px !important;
  min-width: 200px !important;
  flex: 1;
  white-space: nowrap;
  text-align: center;
}

.cta-button.waitlist-button {
  background-color: #00b5ad !important;
  color: white !important;
}

.cta-button.telegram-button {
  background: rgba(51, 144, 236, 0.3) !important;
  border: 1px solid rgba(255, 255, 255, 0.8) !important;
  color: white !important;
}

.hero-section .features-container {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: auto;
  width: 80%;
  padding: 0;
  border: 1px solid rgba(255, 184, 0, 1);
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box {
  flex: 1;
  padding: 3rem;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  cursor: pointer;
  border-right: 1px solid rgba(255, 184, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box:last-child {
  border-right: none;
}

.feature-box h3 {
  color: #fff;
  font-size: 2rem;
  margin: 1rem 0;
  font-weight: 400;
  font-family: 'Helvetica Neue', sans-serif;
  letter-spacing: 0.5px;
}

.feature-box p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 300;
}

.feature-box i.icon {
  color: #ffb800;
  font-size: 2rem !important;
  margin-bottom: 1rem;
}

.feature-box.strategies {
  background: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box.automation {
  background: rgba(161, 46, 32, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box.insights {
  background: rgba(42, 42, 42, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box.security {
  background: rgba(61, 47, 36, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

/* Remove hover effect as it's not in the screenshot */
.feature-box:hover {
  transform: none;
}

/* Media queries for feature boxes */
@media (max-width: 1024px) {
  .feature-box {
    padding: 2rem;
  }

  .feature-box h3 {
    font-size: 1.5rem;
  }

  .feature-box p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .hero-section .features-container {
    width: 100%;
    flex-direction: column;
  }

  .feature-box {
    border-right: none;
    border-bottom: 1px solid rgba(255, 184, 0, 0.3);
    padding: 1.5rem;
  }

  .feature-box:last-child {
    border-bottom: none;
  }

  .feature-box h3 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }

  .feature-box i.icon {
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem;
  }
}

/* Hide on mobile */
@media (max-width: 768px) {
  .hero-section .features-container {
    display: none;
  }
  .cta-box {
    padding: 2rem 1rem;
  }

  .cta-box h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .cta-box p {
    font-size: 1rem;
    padding: 1rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 0.75rem;
  }

  .cta-button {
    width: 100% !important;
    min-width: unset !important;
    padding: 0.75rem 1.5rem !important;
  }
}

@media (max-width: 480px) {
  .hero-section .features-container {
    display: none;
  }

  .cta-box h1 {
    font-size: 2rem;
  }

  .cta-box {
    padding: 1.5rem 1rem;
  }
}

:root {
  --green: #21ba45;
  --green-light: rgba(33, 186, 69, 0.3);
  --red: #db2828;
  --red-light: rgba(219, 40, 40, 0.3);
  --orange: #f2711c;
  --orange-light: rgba(242, 113, 28, 0.3);
  --teal: #00b5ad;
  --teal-light: rgba(0, 181, 173, 0.3);
  --grey: #767676;
  --grey-light: rgba(118, 118, 118, 0.3);
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

.bounce {
  animation: bounce 2s infinite ease-in-out;
}

.shake {
  animation: shake 2s infinite ease-in-out;
}

.market-phase-icon {
  transition: all 0.3s ease;
}

.market-phase-icon:hover {
  transform: scale(1.1);
}

.phase-text-container {
  margin-top: 10px;
}

.phase-text {
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 0 !important;
}

/* Add this new rule with higher specificity to override Semantic UI */
.ui.top.attached.label:first-child + :not(.attached).phase-text {
  margin-top: 0 !important;
}

.phase-text:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.15);
}

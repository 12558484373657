@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Great+Vibes&display=swap);
.ui.toggle.checkbox input:not(:checked) ~ .box:before,
.ui.toggle.checkbox input:not(:checked) ~ label:before {
  background-color: #e0e0e0 !important;
}

.ui.statistic .value {
  font-size: 1.2em;
  font-weight: bold;
}

.ui.statistic .label {
  font-size: 0.9em;
  color: rgba(228, 226, 223, 0.6);
}

.ui.container {
  width: 100%;
}

.Header.Navigation {
  width: 100%;
  padding: 15px;
  border-color: #e7e7e7;
  display: inline-block;
  background-color: #f8f8f8;
  margin-bottom: 20px;
}

.header .logo img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  border-radius: 50%;
  border-top-right-radius: 0;
}

.Navigation ul {
  float: left;
  margin: 0;
}

.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login-container {
  display: flex;
  position: relative;
  padding: 4em;
  width: 100%;
  justify-content: center;
}
.login-container img {
  background: #292c2f;
  border-radius: 50%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  /* also try this #181a1a; */
}

.login-container button {
  border-radius: 50% !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.backdrop-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backdrop-filter: sepia(0.7) blur(0.8px);
          backdrop-filter: sepia(0.7) blur(0.8px);
}

.tableContainer .ui.table:first-child {
  margin: 1em 0;
}

.Navigation ul li {
  float: left;
  position: relative;
  display: block;
  padding: 5px;
}

.Notifications {
  padding: 5px;
  order: 3;
  min-width: 115px;
  display: flex;
  justify-content: flex-end;
}

.Notifications > span {
  float: left;
  padding-right: 8px;
  color: white;
}

.notification-items {
  display: block;
}

.ui.dropdown > .dropdown.icon {
  color: white;
}

.nodisplay {
  display: none !important;
}

.textcenter {
  text-align: center;
}

.cards {
  background: #e1e1e1;
  text-align: center;
  margin: 0px;
  min-height: 170px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.cards > h2 {
  font-size: 16px;
  padding-top: 15px;
  color: #946b00;
}

.cards h5 {
  color: #a9a5a5;
}

.ui.section.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ui.form {
  font-size: 1rem;
  padding: 0 15px;
}

.ui.form .fields {
  margin: 0 -0.5em 0.5em;
}

/*DASHBOBARD TABLE STYLES START*/
.coinholdings {
  margin: 15px;
}
.coinholdings .ui.collapsing.table {
  padding: 0.3em;
  margin: auto !important;
}
.coinholdings .form-control,
.coinholdings table {
  width: 100%;
}

.floatRight {
  float: right;
}
.infoHeader {
  background-color: transparent !important;
}
.coinholdings .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

table#coins {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0;
}

table#coins tr {
  list-style: none;
  border-bottom: 1px solid #ccc;
}

table#coins tbody tr td:first-child i {
  font-size: 9px;
}

table#coins tbody tr td:first-child a {
  float: right;
}

table#coins thead tr:first-child td {
  font-weight: bold;
  text-align: center;
}

table#coins td {
  padding: 5px 15px;
  display: inline-block;
  border-right: 1px solid #ccc;
  width: 10%;
}
.coinholdings tr td {
  position: relative;
}

table#coins td:last-child {
  border-right: 0;
}

.coinholdings tr td i.perc {
  position: absolute;
  left: -20px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background: #fff;
  color: #000;
  padding: 5px;
  height: 30px;
  width: 30px;
  top: -1px;
  box-shadow: 1px 1px 0px 0px;
  text-align: center;
}

.freeCoinsAll a,
.freeCoinsSome a,
.freeCoinsNonePB a {
  color: #fff;
}

.freeCoinsAll {
  background: #0a8415;
  color: #fff;
}

.freeCoinsSome {
  background: #0c87d0;
  color: #fff;
}

.freeCoinsNone {
  background: #fff;
  color: #000;
}
.coinholdings tr td {
  position: relative;
}
table#coins tr {
  list-style: none;
  border-bottom: 1px solid #ccc;
}

table#coins td {
  padding: 5px 15px;
  display: inline-block;
  border-right: 1px solid #ccc;
  width: 10%;
  border-bottom: 1px solid #ccc;
}
.coinholdings tr input {
  border: none;
  width: 100%;
}

.coinholdings tr td button {
  text-align: center;
  float: none;
  display: inline-block;
  margin: 0 auto;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
/*DASHBOBARD TABLE STYLES END*/

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.vmiddle {
  vertical-align: middle !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #0000005e;
  color: #fff;
  text-align: center;
  padding: 1px 0;
  border-radius: 6px;
  position: absolute;
  top: -11px;
  z-index: 999999;
  font-size: 9px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.noBorder {
  border: 0 !important;
}

.marginAuto {
  margin: auto !important;
  display: block !important;
}

.marginTop {
  margin-top: 8px !important;
}

.marginBottom {
  margin-bottom: 16px !important;
}

.width100 {
  width: 100px;
}

.clndr-calendars-segment.clndr-floating {
  bottom: 0;
}

header.header {
  top: 0;
  width: 100%;
  box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
  background-color: #000000 !important;
  z-index: 999;
}

.header.infoHeader {
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
}

.logo {
  order: 1;
}

.logo a {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.logo a span {
  color: #8739fa;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  list-style: none;
}

.ui.vertical.divider {
  z-index: 0;
}

ul.menu li a {
  display: block;
  padding: 7px 15px;
  font-weight: 500;
  transition: 0.2s all ease-in-out;
  color: #fff;
  list-style: none;
}

.menu:hover a {
  opacity: 0.4;
}

.menu a:hover {
  opacity: 1;
  color: #fff;
}

.menu-icon {
  display: none;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked ~ .menu {
  transform: scale(1, 1);
}

.ui.tertiary.segment {
  background-color: transparent;
}

.backtest-multi .ui.steps:first-child {
  margin-top: 14px;
}

.menu.transition.left.visible {
  max-height: 80vh;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  body {
    background-size: contain !important;
  }
  header.header {
    position: -webkit-sticky;
    position: sticky;
  }

  .header .logo img {
    width: 60px;
    height: 60px;
    position: relative;
    top: 0;
    border-radius: 0;
  }

  .dashboardContainer .column {
    padding-top: 0;
  }
  .hide-on-mobile {
    display: none !important;
  }

  .no-padding-mobile {
    padding: 0 !important;
  }

  .coinholdings {
    margin: 15px 0;
  }

  .menu.transition.left.visible {
    left: 50% !important;
    transform: translateX(-75%);
  }

  th#currentPrice {
    display: none !important;
  }

  .coinholdings .ui.collapsing.table {
    padding: 0;
    border: 0;
  }

  .ui.collapsing.table {
    width: 100%;
  }
  .ui.table:not(.unstackable) tr {
    width: inherit !important;
    text-align: center;
  }

  .ui.table:not(.unstackable) thead tr:first-child {
    /* display: none !important; */
  }
  .ui.table:not(.unstackable) thead tr:last-child {
    font-size: 11px;
  }

  .ui.table:not(.unstackable) tbody tr td img.coin-icon {
    display: none;
  }

  .ui.icon.input {
    margin-bottom: 0 !important;
  }

  .cards {
    min-height: auto;
  }

  .ui.table:not(.unstackable) tr {
    display: flex !important;
    position: relative;
  }

  body .coinholdings table tbody tr td,
  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) tr > th {
    display: block !important;
    width: -webkit-fill-available !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .coinholdings table td#currentPrice span.floatRight {
    display: none;
  }

  .coinholdings table td#rank {
    width: 100px !important;
  }
  .coinholdings table td#alerts {
    position: absolute;
    width: -webkit-fill-available !important;
    bottom: 0;
    padding: 0 !important;
  }

  .coinholdings table td#alerts > div {
    height: 5px !important;
    opacity: 0.7;
  }

  .coinSettingForm .ui.container.stackable.four.column.grid {
    margin: 0 auto !important;
  }
  .coinSettingForm .ui.stackable.grid > .column:not(.row) {
    width: 50% !important;
  }

  .login-container {
    flex-direction: column-reverse;
    padding: 1em 2em;
    align-items: center;
  }
  .login-container button {
    padding-bottom: 0;
    margin-bottom: 1em;
    border-radius: 0 !important;
  }
  .login-container img {
    background: transparent;
    /* also try this #181a1a; */
  }

  .ui.stackable.grid > .column:not(.row).no-padding-mobile {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Add this to handle the card spacing specifically */
  .ui.stackable.grid > .column:not(.row) .cards {
    margin: 0.25rem 0;
  }

  /* Add this new style for alerts header */
  .coinholdings .ui.table:not(.unstackable) thead tr th[data-id='alerts'] {
    display: none !important;
    width: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
}

@media only screen and (max-width: 950px) {
  .coinholdings table {
    width: 100% !important;
  }

  .coinholdings table thead {
    display: none;
  }

  ul.menu {
    flex-direction: column;
    background-color: #151418;
    align-items: start;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 0;
    list-style: none;
    padding: 16px;
  }

  ul.menu a {
    margin-left: 12px;
  }

  ul.menu li {
    margin-bottom: 10px;
  }

  .menu-icon {
    display: block;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
  }
}

:root {
  --green: #21ba45;
  --green-light: rgba(33, 186, 69, 0.3);
  --red: #db2828;
  --red-light: rgba(219, 40, 40, 0.3);
  --orange: #f2711c;
  --orange-light: rgba(242, 113, 28, 0.3);
  --teal: #00b5ad;
  --teal-light: rgba(0, 181, 173, 0.3);
  --grey: #767676;
  --grey-light: rgba(118, 118, 118, 0.3);
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

.bounce {
  animation: bounce 2s infinite ease-in-out;
}

.shake {
  animation: shake 2s infinite ease-in-out;
}

.market-phase-icon {
  transition: all 0.3s ease;
}

.market-phase-icon:hover {
  transform: scale(1.1);
}

.phase-text-container {
  margin-top: 10px;
}

.phase-text {
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 0 !important;
}

/* Add this new rule with higher specificity to override Semantic UI */
.ui.top.attached.label:first-child + :not(.attached).phase-text {
  margin-top: 0 !important;
}

.phase-text:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.15);
}

/* Base Styles */
body {
  color: #fff;
  font-family: system-ui;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Layout & Typography */
.sales-page section {
  padding: 60px 0;
  margin-bottom: 40px;
}

.sales-page main {
  padding: 20px;
}

.sales-page h2 {
  color: #946b00;
  padding-bottom: 5px;
  font-family: 'DM Serif Display', serif;
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}

.sales-page h3 {
  color: #e0321c;
  font-family: 'DM Serif Display', serif;
}

/* Hero Section */
.fullscreen-bg {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-box h1 {
  font-size: 80px;
  line-height: 1;
  margin-bottom: 20px;
  color: #946b00;
  border-bottom: 2px solid #946b00;
  font-family: 'DM Serif Display', serif;
  font-weight: 300;
}

.cta-box h1 div {
  font-family: 'DM Serif Display', serif;
  font-size: 46px;
  color: #fff;
}

.cta-box p {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 500;
}

/* CTA Buttons */
.cta-buttons {
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}

.cta-buttons .cta-button {
  margin: 0 !important;
  min-width: 200px !important;
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  padding: 1.2em 2em !important;
  font-size: 1.1em !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  transition: all 0.2s ease-in-out !important;
}

.cta-buttons .waitlist-button {
  background-color: #00b5ad !important;
  color: white !important;
  border: none !important;
}

.cta-buttons .waitlist-button:hover {
  background-color: #009c95 !important;
  transform: translateY(-2px);
}

.cta-buttons .telegram-button {
  background: transparent !important;
  color: white !important;
  border: 2px solid white !important;
}

.cta-buttons .telegram-button:hover {
  background: rgba(51, 144, 236, 0.6) !important;
  transform: translateY(-2px);
}

.cta-buttons .login-button {
  background: transparent !important;
  color: white !important;
  border: 2px solid #2185d0 !important;
}

.cta-buttons .login-button:hover {
  background: rgba(33, 133, 208, 0.1) !important;
  transform: translateY(-2px);
}

/* Features Section */
.sales-page .features-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  padding: 60px 0;
}

.features-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-section-header {
  font-size: 48px !important;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'DM Serif Display', serif;
}

.feature-section-subheader {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin-bottom: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.features-content {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.feature-card-container {
  height: 100vh;
  position: relative;
}

.feature-card {
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.feature-card.active {
  position: fixed;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0s linear;
}

.feature-card-inner {
  height: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(212, 175, 55, 0.5);
  border-radius: 10px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.feature-card-content {
  flex: 0.8 1;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-card-content .icon {
  font-size: 3rem !important;
  color: #946b00;
  margin-bottom: 1.5rem;
}

.feature-card-content .header {
  font-size: 2.5rem !important;
  color: #946b00;
  margin-bottom: 1.5rem;
  font-family: 'DM Serif Display', serif;
}

.feature-card-content .description {
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  color: #333;
}

.feature-card-image {
  flex: 1.2 1;
  background-size: cover;
  background-position: center;
  min-height: 300px;
}

/* Trading Pairs Section */
.trading-pairs ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
}

.trading-pairs ul li {
  margin: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.trading-pairs ul li img {
  width: 40px;
  height: 40px;
  margin-right: 4px;
  background-color: #dbdbdb !important;
  border-radius: 50%;
  padding: 6px;
}

/* Start Today Section */
.start-today {
  background-color: #111;
  padding: 20px;
  text-align: center;
  background-size: cover;
  background-position: center;
}

.start-today div {
  max-width: 800px;
  margin: 0 auto;
}

.sales-page section.start-today div h2 {
  color: #946b00;
  font-size: 72px;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

.sales-page section.start-today div h2 span,
.sales-page section.start-today div h2 div {
  color: #fff;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: italic;
}

.sales-page section.start-today div h2 div {
  display: block;
  font-size: 62px;
}

.sales-page section.past-performance.backtest {
  background-color: #111;
}

/* Gold Effect */
.gold-effect {
  background: linear-gradient(
    to bottom,
    #f7de94 0%,
    #d6bb6e 25%,
    #f7de94 50%,
    #bf953f 75%,
    #f7de94 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 2px rgba(255, 223, 103, 0.2),
    0px 1px 2px rgba(190, 142, 31, 0.4), 1px 1px 4px rgba(190, 142, 31, 0.1),
    1px 2px 6px rgba(255, 215, 0, 0.4);
  position: relative;
  letter-spacing: 0.03em;
  font-weight: 600;
  overflow: hidden;
}

.gold-effect::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: shimmer 5s infinite;
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(30deg);
  }
  20% {
    transform: translateX(100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) rotate(30deg);
  }
}

/* Table Styles */
.sales-page .how-it-works .table img,
.sales-page .ReactTable img {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  margin-right: 4px !important;
  background-color: #dbdbdb !important;
  border-radius: 50% !important;
  padding: 3px !important;
  vertical-align: middle !important;
}

.sales-page .how-it-works .table td,
.sales-page .ReactTable td {
  vertical-align: middle !important;
}

/* Ensure table cells with images align content properly */
.sales-page .how-it-works .table td:first-child,
.sales-page .ReactTable td:first-child {
  display: flex !important;
  align-items: center !important;
  grid-gap: 4px !important;
  gap: 4px !important;
  padding: 0.5em !important;
}

/* Mobile adjustments for tables */
@media (max-width: 768px) {
  /* Layout */
  .sales-page main {
    padding: 0px;
  }

  /* Typography */
  .sales-page h1 {
    font-size: 2.5rem !important;
    line-height: 1.2 !important;
  }

  .sales-page h2 {
    font-size: 2rem !important;
    line-height: 1.3 !important;
  }

  .sales-page h3 {
    font-size: 1.5rem !important;
    line-height: 1.4 !important;
  }

  .sales-page .cta-box h1 {
    font-size: 2.8rem !important;
    line-height: 1.2 !important;
  }

  .sales-page .feature-section-header {
    font-size: 32px !important;
  }

  .sales-page .feature-section-subheader {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .sales-page .start-today h2 {
    font-size: 2.5rem !important;
    line-height: 1.2 !important;
  }

  /* Features */
  .feature-card-inner {
    flex-direction: column;
  }

  .feature-card-content {
    padding: 2rem;
  }

  .feature-card-content .header {
    font-size: 2rem !important;
  }

  .feature-card-content .description {
    font-size: 1.1rem !important;
  }

  .feature-card-image {
    min-height: 250px;
  }

  /* Trading Pairs */
  .trading-pairs ul {
    justify-content: center;
    max-height: 300px;
    overflow-y: auto;
  }

  /* CTA Buttons */
  .cta-buttons {
    flex-direction: column;
    align-items: stretch;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 1rem;
  }

  .cta-buttons .cta-button {
    width: 100% !important;
    max-width: none !important;
    padding: 1em 1.5em !important;
    font-size: 1em !important;
    margin-bottom: 0.5rem !important;
  }

  .bottom-cta-buttons {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .bottom-cta-buttons .cta-button {
    font-size: 1em !important;
  }

  .waitlist-button.floating {
    bottom: 1rem !important;
    right: 1rem !important;
    font-size: 0.9rem !important;
    padding: 0.8em 1.5em !important;
  }

  .sales-page .how-it-works .table img,
  .sales-page .ReactTable img {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 2px !important;
  }

  .sales-page .how-it-works .table td:first-child,
  .sales-page .ReactTable td:first-child {
    padding: 0.3em !important;
  }
}

/* Floating Button Styles */
.waitlist-button.floating {
  position: fixed !important;
  bottom: 2rem !important;
  right: 2rem !important;
  z-index: 9999 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 30px !important;
  padding: 1em 2em !important;
  margin: 0 !important;
  transform: none !important;
  background-color: #00b5ad !important;
  color: white !important;
  border: none !important;
  transition: transform 0.2s ease-in-out !important;
}

.waitlist-button.floating:hover {
  transform: translateY(-2px) !important;
  background-color: #009c95 !important;
}

.fullscreen-bg {
  position: relative;
  min-height: 100vh;
  background: #111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.cta-box {
  position: relative;
  z-index: 1;
  padding: 4rem 2rem;
  color: white;
}

.cta-box h1 {
  font-size: 4rem;
  margin-bottom: 2rem;
}

.cta-box p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 600px;
  background: rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  border-radius: 4px;
  color: white;
}

.cta-buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.cta-button {
  padding: 1rem 2rem !important;
  font-size: 1rem !important;
  border-radius: 4px !important;
  min-width: 200px !important;
  flex: 1 1;
  white-space: nowrap;
  text-align: center;
}

.cta-button.waitlist-button {
  background-color: #00b5ad !important;
  color: white !important;
}

.cta-button.telegram-button {
  background: rgba(51, 144, 236, 0.3) !important;
  border: 1px solid rgba(255, 255, 255, 0.8) !important;
  color: white !important;
}

.hero-section .features-container {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: auto;
  width: 80%;
  padding: 0;
  border: 1px solid rgba(255, 184, 0, 1);
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box {
  flex: 1 1;
  padding: 3rem;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  cursor: pointer;
  border-right: 1px solid rgba(255, 184, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box:last-child {
  border-right: none;
}

.feature-box h3 {
  color: #fff;
  font-size: 2rem;
  margin: 1rem 0;
  font-weight: 400;
  font-family: 'Helvetica Neue', sans-serif;
  letter-spacing: 0.5px;
}

.feature-box p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 300;
}

.feature-box i.icon {
  color: #ffb800;
  font-size: 2rem !important;
  margin-bottom: 1rem;
}

.feature-box.strategies {
  background: rgba(26, 26, 26, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box.automation {
  background: rgba(161, 46, 32, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box.insights {
  background: rgba(42, 42, 42, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.feature-box.security {
  background: rgba(61, 47, 36, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

/* Remove hover effect as it's not in the screenshot */
.feature-box:hover {
  transform: none;
}

/* Media queries for feature boxes */
@media (max-width: 1024px) {
  .feature-box {
    padding: 2rem;
  }

  .feature-box h3 {
    font-size: 1.5rem;
  }

  .feature-box p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .hero-section .features-container {
    width: 100%;
    flex-direction: column;
  }

  .feature-box {
    border-right: none;
    border-bottom: 1px solid rgba(255, 184, 0, 0.3);
    padding: 1.5rem;
  }

  .feature-box:last-child {
    border-bottom: none;
  }

  .feature-box h3 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }

  .feature-box i.icon {
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem;
  }
}

/* Hide on mobile */
@media (max-width: 768px) {
  .hero-section .features-container {
    display: none;
  }
  .cta-box {
    padding: 2rem 1rem;
  }

  .cta-box h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .cta-box p {
    font-size: 1rem;
    padding: 1rem;
  }

  .cta-buttons {
    flex-direction: column;
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .cta-button {
    width: 100% !important;
    min-width: unset !important;
    padding: 0.75rem 1.5rem !important;
  }
}

@media (max-width: 480px) {
  .hero-section .features-container {
    display: none;
  }

  .cta-box h1 {
    font-size: 2rem;
  }

  .cta-box {
    padding: 1.5rem 1rem;
  }
}


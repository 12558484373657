@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Great+Vibes&display=swap');

/* Base Styles */
body {
  color: #fff;
  font-family: system-ui;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Layout & Typography */
.sales-page section {
  padding: 60px 0;
  margin-bottom: 40px;
}

.sales-page main {
  padding: 20px;
}

.sales-page h2 {
  color: #946b00;
  padding-bottom: 5px;
  font-family: 'DM Serif Display', serif;
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}

.sales-page h3 {
  color: #e0321c;
  font-family: 'DM Serif Display', serif;
}

/* Hero Section */
.fullscreen-bg {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-box h1 {
  font-size: 80px;
  line-height: 1;
  margin-bottom: 20px;
  color: #946b00;
  border-bottom: 2px solid #946b00;
  font-family: 'DM Serif Display', serif;
  font-weight: 300;
}

.cta-box h1 div {
  font-family: 'DM Serif Display', serif;
  font-size: 46px;
  color: #fff;
}

.cta-box p {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 500;
}

/* CTA Buttons */
.cta-buttons {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}

.cta-buttons .cta-button {
  margin: 0 !important;
  min-width: 200px !important;
  max-width: fit-content !important;
  padding: 1.2em 2em !important;
  font-size: 1.1em !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  transition: all 0.2s ease-in-out !important;
}

.cta-buttons .waitlist-button {
  background-color: #00b5ad !important;
  color: white !important;
  border: none !important;
}

.cta-buttons .waitlist-button:hover {
  background-color: #009c95 !important;
  transform: translateY(-2px);
}

.cta-buttons .telegram-button {
  background: transparent !important;
  color: white !important;
  border: 2px solid white !important;
}

.cta-buttons .telegram-button:hover {
  background: rgba(51, 144, 236, 0.6) !important;
  transform: translateY(-2px);
}

.cta-buttons .login-button {
  background: transparent !important;
  color: white !important;
  border: 2px solid #2185d0 !important;
}

.cta-buttons .login-button:hover {
  background: rgba(33, 133, 208, 0.1) !important;
  transform: translateY(-2px);
}

/* Features Section */
.sales-page .features-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  padding: 60px 0;
}

.features-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-section-header {
  font-size: 48px !important;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'DM Serif Display', serif;
}

.feature-section-subheader {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin-bottom: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.features-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feature-card-container {
  height: 100vh;
  position: relative;
}

.feature-card {
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.feature-card.active {
  position: fixed;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0s linear;
}

.feature-card-inner {
  height: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(212, 175, 55, 0.5);
  border-radius: 10px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.feature-card-content {
  flex: 0.8;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-card-content .icon {
  font-size: 3rem !important;
  color: #946b00;
  margin-bottom: 1.5rem;
}

.feature-card-content .header {
  font-size: 2.5rem !important;
  color: #946b00;
  margin-bottom: 1.5rem;
  font-family: 'DM Serif Display', serif;
}

.feature-card-content .description {
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  color: #333;
}

.feature-card-image {
  flex: 1.2;
  background-size: cover;
  background-position: center;
  min-height: 300px;
}

/* Trading Pairs Section */
.trading-pairs ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
}

.trading-pairs ul li {
  margin: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.trading-pairs ul li img {
  width: 40px;
  height: 40px;
  margin-right: 4px;
  background-color: #dbdbdb !important;
  border-radius: 50%;
  padding: 6px;
}

/* Start Today Section */
.start-today {
  background-color: #111;
  padding: 20px;
  text-align: center;
  background-size: cover;
  background-position: center;
}

.start-today div {
  max-width: 800px;
  margin: 0 auto;
}

.sales-page section.start-today div h2 {
  color: #946b00;
  font-size: 72px;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

.sales-page section.start-today div h2 span,
.sales-page section.start-today div h2 div {
  color: #fff;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: italic;
}

.sales-page section.start-today div h2 div {
  display: block;
  font-size: 62px;
}

.sales-page section.past-performance.backtest {
  background-color: #111;
}

/* Gold Effect */
.gold-effect {
  background: linear-gradient(
    to bottom,
    #f7de94 0%,
    #d6bb6e 25%,
    #f7de94 50%,
    #bf953f 75%,
    #f7de94 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 0px 2px rgba(255, 223, 103, 0.2),
    0px 1px 2px rgba(190, 142, 31, 0.4), 1px 1px 4px rgba(190, 142, 31, 0.1),
    1px 2px 6px rgba(255, 215, 0, 0.4);
  position: relative;
  letter-spacing: 0.03em;
  font-weight: 600;
  overflow: hidden;
}

.gold-effect::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: shimmer 5s infinite;
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(30deg);
  }
  20% {
    transform: translateX(100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) rotate(30deg);
  }
}

/* Table Styles */
.sales-page .how-it-works .table img,
.sales-page .ReactTable img {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  margin-right: 4px !important;
  background-color: #dbdbdb !important;
  border-radius: 50% !important;
  padding: 3px !important;
  vertical-align: middle !important;
}

.sales-page .how-it-works .table td,
.sales-page .ReactTable td {
  vertical-align: middle !important;
}

/* Ensure table cells with images align content properly */
.sales-page .how-it-works .table td:first-child,
.sales-page .ReactTable td:first-child {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
  padding: 0.5em !important;
}

/* Mobile adjustments for tables */
@media (max-width: 768px) {
  /* Layout */
  .sales-page main {
    padding: 0px;
  }

  /* Typography */
  .sales-page h1 {
    font-size: 2.5rem !important;
    line-height: 1.2 !important;
  }

  .sales-page h2 {
    font-size: 2rem !important;
    line-height: 1.3 !important;
  }

  .sales-page h3 {
    font-size: 1.5rem !important;
    line-height: 1.4 !important;
  }

  .sales-page .cta-box h1 {
    font-size: 2.8rem !important;
    line-height: 1.2 !important;
  }

  .sales-page .feature-section-header {
    font-size: 32px !important;
  }

  .sales-page .feature-section-subheader {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .sales-page .start-today h2 {
    font-size: 2.5rem !important;
    line-height: 1.2 !important;
  }

  /* Features */
  .feature-card-inner {
    flex-direction: column;
  }

  .feature-card-content {
    padding: 2rem;
  }

  .feature-card-content .header {
    font-size: 2rem !important;
  }

  .feature-card-content .description {
    font-size: 1.1rem !important;
  }

  .feature-card-image {
    min-height: 250px;
  }

  /* Trading Pairs */
  .trading-pairs ul {
    justify-content: center;
    max-height: 300px;
    overflow-y: auto;
  }

  /* CTA Buttons */
  .cta-buttons {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    padding: 0 1rem;
  }

  .cta-buttons .cta-button {
    width: 100% !important;
    max-width: none !important;
    padding: 1em 1.5em !important;
    font-size: 1em !important;
    margin-bottom: 0.5rem !important;
  }

  .bottom-cta-buttons {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .bottom-cta-buttons .cta-button {
    font-size: 1em !important;
  }

  .waitlist-button.floating {
    bottom: 1rem !important;
    right: 1rem !important;
    font-size: 0.9rem !important;
    padding: 0.8em 1.5em !important;
  }

  .sales-page .how-it-works .table img,
  .sales-page .ReactTable img {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 2px !important;
  }

  .sales-page .how-it-works .table td:first-child,
  .sales-page .ReactTable td:first-child {
    padding: 0.3em !important;
  }
}

/* Floating Button Styles */
.waitlist-button.floating {
  position: fixed !important;
  bottom: 2rem !important;
  right: 2rem !important;
  z-index: 9999 !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 30px !important;
  padding: 1em 2em !important;
  margin: 0 !important;
  transform: none !important;
  background-color: #00b5ad !important;
  color: white !important;
  border: none !important;
  transition: transform 0.2s ease-in-out !important;
}

.waitlist-button.floating:hover {
  transform: translateY(-2px) !important;
  background-color: #009c95 !important;
}
